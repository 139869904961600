<template>
  <header class="position-absolute bg-opacity-50 w-100 maslenicaHeder">
    <div class="row p-3">
      <div class="col-10">
        <div class="row">
          <label class="w-100 h2">
            Собранные ярила
          </label>
        </div>
        <div class="row">
          <div class="col-12">
            <template v-if="userSettings.inventary && userSettings.inventary.cristalls">
            <span
                v-for="sun in userSettings.inventary.cristalls"
                :key="sun"
                style="color:#f18841">
                  <font-awesome-icon icon="fa-sun"/>
            </span>
            </template>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="float-end">
          <button type="button" class="btn btn-lg btn-danger" @click="$emit('restart')">
            <font-awesome-icon icon="arrows-rotate"/>
          </button>
        </div>
      </div>
    </div>
    <div class="row" v-if="this.gameSettings.load!==false">
      <div class="col-12 text-center text-bg-danger">
        Загрузка...
      </div>
    </div>
  </header>
</template>

<script>
import changeEmit from "@/mixins/changeEmit";

export default {
  name: "templateHeaderMaslenica",
  props: {
    userSettings: Object,
    gameSettings: Object,
  },
  emits: [
    'change',
    'restart'
  ],
  mixins: [changeEmit],
  methods: {
    stopGame() {
      this.sendEmit('gameSettings.gameState', 'fail');
    },
    changeTimeDuration(event) {
      this.sendEmit('gameSettings.diffTimer', event);
    }
  }
}
</script>

<style scoped>
header {
  z-index: 99999;
}

.maslenicaHeder {
  background-color: #feea70;
}
.maslenicaHeder span{
  margin-right: 10px;
}
</style>