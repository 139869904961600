<template>
  <a-gltf-model rotation="0 0 0 " position="0 0 0" scale="0.5 0.5 0.5"
                src="#cristal"
                @click="event"
                animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
  ></a-gltf-model>
</template>

<script>
import marker from "@/mixins/marker";

export default {
  name: "cristalMarker",
  mixins: [marker],
  data() {
    return {
      soundUrl: '/assets/sounds/getCristall.mp3'
    }
  }
}
</script>

<style scoped>

</style>