<template>
  <a-gltf-model rotation="0 0 0 " position="0 0 0" scale="0.5 0.5 0.5"
                src="#question"
                @click="init"
                animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
  ></a-gltf-model>
</template>

<script>
import marker from "@/mixins/marker";

export default {
  name: "questionMarker",
  mixins: [marker],
  data() {
    return {
      soundUrl: '/assets/sounds/question.mp3'
    }
  },
  mounted() {
    document.addEventListener('changeQuestionSend', () => {
      this.checkAnswer();
    });
  },
  methods: {
    init() {
      if (this.$props.marker.show === true) {
        let question = {
          text: this.$props.marker.question_marker.question,
          type: this.$props.marker.question_marker.input_type ? this.$props.marker.question_marker.input_type : 'text',
          variants: this.$props.marker.question_marker.input_variants ? JSON.parse(this.$props.marker.question_marker.input_variants) : []
        }
        switch (this.$props.marker.event_action.value) {
          default:
            this.sendEmit(
                'userSettings.question.text',
                question
            );
            this.sendEmit(
                'userSettings.question.image',
                this.$props.marker.question_marker.image
            );
            this.sendEmit(
                'userSettings.markerId',
                this.$props.marker.id
            );
            this.sendEmit(
                'userSettings.question.show',
                true
            );
            this.sendEmit(
                'gameSettings.showFooter',
                true
            );
        }
        this.playSound();
      }
    },
    //Проверка ответа
    checkAnswer() {
      try {
        if (this.$props.userSettings.markerId !== this.$props.marker.id) {
          return false;
        }

        if (Array.isArray(this.$props.userSettings.question.answer)) {
          if (
              this.$props.marker.question_marker.multy_answer === true &&
              this.compareArray(
                  this.$props.userSettings.question.answer,
                  JSON.parse(this.$props.marker.question_marker.answer)
              ) === true
          ) {
            this.runEvent();
          } else if (
              this.$props.userSettings.question.answer.indexOf(
                  this.$props.marker.question_marker.answer
              ) !== -1
          ) {
            this.runEvent();
          }
        } else {
          if (this.$props.marker.question_marker.answer === this.$props.userSettings.question.answer) {
            this.runEvent();
          }
        }

        this.$props.marker.show = false;

        this.resetQuestion();
      } catch (e) {
        console.error(e);
      }
    },
    //Проверяем массивы на соответствие
    compareArray(a, b) {
      if (a.length !== b.length) {
        return false;
      }
      let results = 0;
      a.map((item) => {
        if (b.indexOf(item) !== -1) {
          results++;
        }
      });
      return results === a.length;
    },
    //Запускает событие маркера. Действия при срабатывании
    runEvent() {
      this.event();
    },
    //Сброс состояния
    resetQuestion() {
      this.sendEmit(
          'userSettings.question.text',
          ""
      );
      this.sendEmit(
          'userSettings.question.image',
          ""
      );
      this.sendEmit(
          'userSettings.question.show',
          false
      );
      this.sendEmit(
          'gameSettings.showFooter',
          false
      );
    }
  }
}
</script>

<style scoped>

</style>