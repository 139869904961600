<template>
  <footer class="position-absolute bg-light w-100 bottom-0 maslenica"
          style="background-image: url('/assets/images/questionBackground.png')">

    <div class="row d-flex flex-row-reverse">
      <button type="button"
              class="btn-close"
              @click="close"
      ></button>
    </div>
    <div class="row p-3">
      <QuestionComponent :user-settings="userSettings"
                         @send-answer="sendQuestionAnswer"
      ></QuestionComponent>
    </div>
  </footer>
</template>

<script>
import QuestionComponent from "@/components/question";
import changeEmit from "@/mixins/changeEmit";

export default {
  name: "templateFooterMaslenica",
  components: {QuestionComponent},
  props: {
    userSettings: Object
  },
  mixins: [changeEmit],
  emits: [
    'change'
  ],
  methods: {
    sendQuestionAnswer(event) {
      this.sendEmit(
          'userSettings.question.send',
          true
      );
      this.sendEmit(
          'userSettings.question.answer',
          event
      );

      document.dispatchEvent(new CustomEvent('changeQuestionSend',{detail: event}))
    },
    close() {
      this.sendEmit(
          'gameSettings.showFooter',
          false
      );
    }
  }
}
</script>

<style scoped>
footer {
  z-index: 99999;
}

.maslenica {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.maslenica .btn-close{
  margin-top: 1.5rem;
  margin-right: 1.5rem;
}
</style>