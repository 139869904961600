<template>
  <div class="position-absolute bg-info w-100 h-100 d-flex align-items-center startMaslenica"
  style="background-image: url('/assets/images/headerBackground.jpeg')">
    <div class="align-middle text-center w-100 h1 placeholder-glow">
      <div class="rules">
        <h2>Как играть?</h2>
        <ul>
          <li>Найдите маркер. (картинка-стикер с штрихкодами)</li>
          <li>При Запущенной игре наведите камеру на стикер</li>
          <li>Дождитесь появления вопроса и вариантов ответа на экране</li>
          <li>Ответьте на вопрос. При верном ответе вам начислится 1 ярило</li>
        </ul>

        <h3>Если игра не работает</h3>
        <ul>
          <li>Перезагрузите страницу</li>
          <li>Попробуйте открыть страницу в режиме инкогнито</li>
          <li><a href="https://forms.gle/doSnWP9McjjCDiT47">Заполните форму по ошибке</a></li>
        </ul>
      </div>
      <ul class="list-unstyled">
        <li v-for="(game, gameIndex) in gameList" :key="gameIndex">
          <button type="button" class="btn"
                  @click="selectGame(game.id)">
            <font-awesome-icon icon="fa-sun"/>
            {{ game.name }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import changeEmit from "@/mixins/changeEmit";

export default {
  name: "templateGameListMaslenica",
  props: {
    gameList: Object
  },
  mixins:[
    changeEmit
  ],
  methods: {
    selectGame(gameId) {
      this.sendEmit('game_id', gameId);
    }
  }
}
</script>

<style scoped>
  .startMaslenica{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .startMaslenica .btn{
    font-size: 4rem;
    color: #fb6d05;
  }
  .startMaslenica .btn:hover{
    color: #fb4f05;
  }
  .rules{
    text-align: left;
    padding-left: 3rem;
    padding-right: 1rem;
    height: 15rem;
    overflow: auto;
    font-size: 14px;
  }
</style>