<template>
$END$
</template>

<script>
export default {
  name: "gameEventsMixin",
  methods: {
    //Сохраняет настройки в кеш
    saveCasheSettings() {
      if (this.gameSettings.gameState === 'game') {
        localStorage.setItem('gameSettings', JSON.stringify(this.gameSettings));
        localStorage.setItem('userSettings', JSON.stringify(this.userSettings));
      }
    },
    //Получить кешированные настройки
    getCachedSettings() {
      let cachedGameSettings = localStorage.getItem('gameSettings');
      let cachedUserSettings = localStorage.getItem('userSettings');
      if (cachedGameSettings !== null) {
        cachedGameSettings = JSON.parse(cachedGameSettings);
        if (cachedGameSettings.diffTimer) {
          cachedGameSettings.timer = cachedGameSettings.diffTimer / 60;
        }
        this.gameSettings = cachedGameSettings;
      }
      if (cachedUserSettings !== null) {
        this.userSettings = JSON.parse(cachedUserSettings);
      }
    },
    //Перезагрузка игры со сбросом кеша
    restartGame() {
      caches.keys().then(function(names) {
        for (let name of names)
          caches.delete(name);
      });

      this.gameSettings.gameState = null;
      localStorage.removeItem('gameSettings');
      localStorage.removeItem('userSettings');
      localStorage.removeItem('markers');
      localStorage.removeItem('gameInfo');
      location.reload();
    },
    //Меняет настройки игры по событиям из компонентов
    changeSettings(event) {
      let changeValue = new Function('data', 'value', `return data.${event.dataPath}=value`)
      changeValue(this.$data, event.value);
    }
  }
}
</script>

<style scoped>

</style>