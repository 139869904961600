<template>
  <footer class="position-absolute bg-light bg-opacity-50 w-100 bottom-0">
    <div class="row p-3">
      <div class="row d-flex flex-row-reverse">
        <button type="button"
                class="btn-close"
                @click="close"
        ></button>
      </div>
      <QuestionComponent :user-settings="userSettings"
                         @send-answer="sendQuestionAnswer"
      ></QuestionComponent>
    </div>
  </footer>
</template>

<script>
import QuestionComponent from "@/components/question";
import changeEmit from "@/mixins/changeEmit";

export default {
  name: "templateFooter",
  components: {QuestionComponent},
  props: {
    userSettings: Object
  },
  mixins: [changeEmit],
  emits: [
    'change'
  ],
  methods: {
    sendQuestionAnswer(event) {
      this.sendEmit(
          'userSettings.question.send',
          true
      );
      this.sendEmit(
          'userSettings.question.answer',
          event
      );
    },
    close() {
      this.sendEmit(
          'gameSettings.showFooter',
          false
      );
    }
  }
}
</script>

<style scoped>
footer {
  z-index: 99999;
}
</style>