<template>
  <div class="position-absolute bg-danger w-100 h-100 d-flex align-items-center failMaslenica"
       style="background-image: url('/assets/images/headerBackground.jpeg')">
    <div class="align-middle text-center w-100 h1 placeholder-glow">

      <button type="button" class="btn" @click="$emit('restart')">
        <font-awesome-icon icon="arrows-rotate"/>
        Вы проиграли
      </button>

    </div>
  </div>
</template>

<script>
export default {
  name: "templateGameOverMaslenica",
  emits: [
    'restart'
  ]
}
</script>

<style scoped>
.failMaslenica {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.failMaslenica .btn {
  font-size: 5rem;
  color: #fb6d05;
}

.failMaslenica .btn:hover {
  color: #fb4f05;
}
</style>