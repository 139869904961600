<template>
  <div class="position-absolute bg-info w-100 h-100 d-flex align-items-center">
    <div class="align-middle text-center w-100 h1 placeholder-glow">
      <ul class="list-unstyled">
        <li v-for="(game, gameIndex) in gameList" :key="gameIndex">
          <button type="button" class="btn btn-success w-25" @click="selectGame(game.id)">{{ game.name }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import changeEmit from "@/mixins/changeEmit";

export default {
  name: "templateGameList",
  props: {
    gameList: Object
  },
  mixins:[
    changeEmit
  ],
  methods: {
    selectGame(gameId) {
      this.sendEmit('game_id', gameId);
    }
  }
}
</script>

<style scoped>

</style>