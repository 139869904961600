<template>
  <a-gltf-model rotation="0 0 0 " position="0 0 0" scale="0.05 0.05 0.05"
                src="#timer"
                @click="event"
                animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
  ></a-gltf-model>
</template>

<script>
import marker from "@/mixins/marker";

export default {
  name: "timerMarker",
  mixins: [marker],
  data() {
    return {
      soundUrl: '/assets/sounds/timer.mp3'
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>