<template>
  <span :class="color" class="p-1">{{ display }}</span>
</template>

<script>
import {DateTime, Duration} from "luxon";

export default {
  name: "timerComponent",
  data() {
    return {
      startDateTime: DateTime.local(),
      now: null,
      end: null,
      tick: null,
    }
  },
  props: {
    seconds: Number
  },
  emits: [
    'finish',
    'tick'
  ],
  created() {
    this.now = this.startDateTime.set({milliseconds: 0});
    this.end = this.startDateTime.set({milliseconds: 0}).plus({seconds: this.$props.seconds})
  },
  watch: {
    seconds() {
      this.end = this.startDateTime.set({milliseconds: 0}).plus({seconds: this.$props.seconds});
    },
    now() {
      if (this.finished) {
        clearInterval(this.tick);
        this.$emit('finish', true);
      }
    },
  },
  computed: {
    display() {
      return Duration.fromObject(this.end.diff(this.now).toObject()).toFormat('hh:mm:ss')
    },
    finished() {
      return this.now >= this.end
    },
    color() {
      let duration = Duration.fromObject(this.end.diff(this.now).toObject()).toFormat('mm');
      if (duration < (this.$props.seconds / 60 / 2)) {
        return 'text-bg-warning';
      } else if (duration < (this.$props.seconds / 60 / 3)) {
        return 'text-bg-danger';
      }
      return '';
    }
  },
  mounted() {
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({milliseconds: 0});
      this.$emit('tick', this.end.diff(this.now).toObject().milliseconds / 1000);
    }, 1000);
  },
}
</script>

<style scoped>

</style>