<script>
import changeEmit from "@/mixins/changeEmit";

export default {
  name: "markerMixin",
  props: {
    userSettings: Object,
    gameSettings: Object,
    marker: Object
  },
  mixins: [changeEmit],
  data() {
    return {
      soundUrl: null,
      overrideMarkerEventValue: null
    }
  },
  methods: {
    playSound() {
      if (this.soundUrl !== null) {
        let sound = new Audio(this.soundUrl);
        sound.play();
      }
    },
    event() {
      if (this.$props.marker.visible===true && this.$props.marker.show === true) {
        let markerEventValue = (this.overrideMarkerEventValue === null) ? this.$props.marker.event_value : this.overrideMarkerEventValue;
        switch (this.$props.marker.event_action.value) {
          case 'addHealth':
            this.sendEmit(
                'userSettings.health',
                this.$props.userSettings.health + ((markerEventValue !== null) ? markerEventValue : 10)
            );
            break;
          case 'removeHealth':
            this.sendEmit(
                'userSettings.health',
                this.$props.userSettings.health - ((markerEventValue !== null) ? markerEventValue : 10)
            );
            break;
          case 'take':
            if (!this.$props.userSettings.inventary.cristalls) {
              this.sendEmit(
                  'userSettings.inventary.cristalls',
                  this.$props.userSettings.inventary.cristalls = ((markerEventValue !== null) ? markerEventValue : 1)
              );
            } else {
              this.sendEmit(
                  'userSettings.inventary.cristalls',
                  this.$props.userSettings.inventary.cristalls + ((markerEventValue !== null) ? markerEventValue : 10)
              );
            }
            break;
          case 'addTime':
            this.sendEmit(
                'gameSettings.timer',
                this.$props.gameSettings.timer + ((markerEventValue !== null) ? markerEventValue : 10)
            );
        }
        this.playSound();
      }
    }
  }

}
</script>