<script>
export default {
  name: "changeEmit",
  methods:{
    sendEmit(dataPath, value) {
      this.$emit(
          'change',
          {
            dataPath: dataPath,
            value: value
          }
      );
    },
  }
}
</script>

<style scoped>

</style>