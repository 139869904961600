<script>
export default {
  name: "userSettings",
  data() {
    return {
      userSettings: {
        health: 100,
        inventary: {},
        question: {
          show: false,
          text: {
            text: "",
            type: "",
            variants: []
          },
          answer: null,
          image: '',
          send: false,
        }
      },
    }
  },
  watch:{
    'userSettings.health'() {
      if (this.userSettings.health > 100) {
        this.userSettings.health = 100;
      }
      if (this.userSettings.health <= 0) {
        this.userSettings.health = 0;
        this.gameSettings.gameState = 'fail';
      }
    },
  }
}
</script>

<style scoped>

</style>