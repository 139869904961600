<template>
  <a-gltf-model rotation="100 0 0 " position="0 0 0" scale="0.2 0.2 0.2"
                src="#bomb"
                @click="event"
                animation="property: position; to: 0 0.1 0.1; dur: 1000; easing: easeInOutQuad; loop: true; dir: alternate"
  ></a-gltf-model>
</template>

<script>
import marker from "@/mixins/marker";

export default {
  name: "bombMarker",
  mixins: [marker],
  data() {
    return {
      soundUrl: '/assets/sounds/removeHealth.mp3'
    }
  }
}
</script>

<style scoped>

</style>