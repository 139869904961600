<template>
$END$
</template>

<script>
export default {
  name: "gqlMixin",
  methods: {
    getGameListQueryString() {
      return `
          query {
            ar_games {
                id
                name
                settings: settings_id {
                    template
                    health
                    show_header
                    show_footer
                    game_timer
                }
            },
          }
        `;
    },
    //Получить строку запроса для получения маркеров
    getMarkersQueryString(gameId) {
      return `
          query {
            ar_games (
                filter: {
                        id: {_eq: ${gameId}}
                    }
                ){
                    id
                name
                settings: settings_id {
                    template
                    health
                    show_header
                    show_footer
                    game_timer
                }
            },
            ar_markers (filter: {
                game_id: {
                    id: { _eq: ${gameId} }
                 }
            }){
                id
                name
                sort
                template{
                    value: code
                }
                event_type{
                    value: name
                }
                event_action{
                    value: action
                }
                question_marker{
                    name
                    question
                    answer
                    image
                    input_type
                    input_variants
                }
                event_value
                information_marker{
                  text
                  src_type
                  src_url
                }
            }
        }
      `
    },
    //Запрос к gql серверу
    gqlRequest(graphqlQuery, callback) {
      fetch(this.$props.graphqlurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: graphqlQuery
        }),
      })
          .then((res) => res.json())
          .then((result) => callback(result));
    }
  }
}
</script>

<style scoped>

</style>