import {createApp} from 'vue'
import App from './App.vue'


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'mind-ar/dist/mindar-image.prod.js';
import 'aframe';
import 'aframe-extras';
import 'mind-ar/dist/mindar-image-aframe.prod.js';
import './registerServiceWorker'


import {library} from '@fortawesome/fontawesome-svg-core'
import {faArrowsRotate, faSun} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add([faArrowsRotate, faSun])


let graphqlurl = 'https://directus.arhvpn.ru/graphql';

createApp(
    App,
    {
        graphqlurl: graphqlurl
    }
)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
