<template>
  <div class="position-absolute bg-success w-100 h-100 d-flex align-items-center winMaslenica"
       style="background-image: url('/assets/images/headerBackground.jpeg')">
    <div class="align-middle text-center w-100 placeholder-glow">

      <div class="row">
        <div class="container-fluid">
          <button type="button" class="btn" @click="$emit('restart')">
            <font-awesome-icon icon="arrows-rotate"/>
            Вы выиграли
          </button>
          <p class="h3">
            Вы собрали {{ userSettings.inventary.cristalls }} ярил! <br>
            Подойдите к организатору, чтобы получить приз согласно условиям!
          </p>
          <p class="h3">
            <a href="https://forms.gle/X5aRXYJJNDbVaA2r7">
              Пожалуйста оставьте отзыв тут!
            </a>
          </p>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
export default {
  name: "templateGameOverMaslenica",
  emits: [
    'restart'
  ],
  props: {
    userSettings: Object,
    gameSettings: Object,
  }
}
</script>

<style scoped>
.winMaslenica {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.winMaslenica .btn {
  font-size: 5rem;
  color: #fb6d05;
}

.winMaslenica .btn:hover {
  color: #fb4f05;
}
</style>