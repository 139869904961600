<template>
  <div class="container-fluid text-center mb-4">
    <img v-if="userSettings && userSettings.question && userSettings.question.image"
         :src="userSettings.question.image"
         class="img-thumbnail mb-1">
    <p class="question-text overflow-auto mb-1 p-2 rounded-2 h4"
       v-if="userSettings && userSettings.question && userSettings.question.text && userSettings.question.text">
      {{ userSettings.question.text.text }}
    </p>

    <input type="text" v-if="userSettings.question.text.type==='text'" v-model="answerText">
    <textarea class="form-control mb-2" v-if="userSettings.question.text.type==='textarea'"
              v-model="answerText"></textarea>

    <ul v-if="userSettings.question.text.type==='radio' || userSettings.question.text.type==='checkbox'"
        class="text-start h5 overflow-auto list-unstyled">
      <li v-for="(variant,variantIndex) in userSettings.question.text.variants" :key="variantIndex">
        <label>
          <input v-if="userSettings.question.text.type==='checkbox'"
                 type="checkbox"
                 :value="variant"
                 v-model="answerCheckbox">

          <input v-else
                 type="radio"
                 :value="variant"
                 v-model="answerRadio">

          {{ variant }}
        </label>
      </li>
    </ul>
  </div>
  <div class="container-fluid text-center">
    <button type="button" class="btn btn-lg btn-success" @click="sendAnswer">Отправить</button>
  </div>
</template>

<script>
export default {
  name: "questionComponent",
  data() {
    return {
      answerText: "",
      answerRadio: null,
      answerCheckbox: [],
    }
  },
  props: {
    userSettings: Object
  },
  emits: [
    'sendAnswer'
  ],
  methods: {
    sendAnswer() {
      switch (this.$props.userSettings.question.text.type) {
        case 'checkbox':
          this.$emit('sendAnswer', this.answerCheckbox);
          break;
        case 'radio':
          this.$emit('sendAnswer', this.answerRadio);
          break;
        default:
          this.$emit('sendAnswer', this.answerText);
          break;
      }
    },
  }
}
</script>

<style scoped>
.img-thumbnail {
  max-width: 10rem !important;
}

.question-text {
  height: 7rem;
}
</style>