<template>
  <div class="position-absolute bg-info bg-opacity-75 w-100 h-100 d-flex align-items-center">
    <span class="align-middle text-center w-100 h1 placeholder-glow">Загрузка</span>
  </div>
</template>

<script>
export default {
  name: "templateLoadGame"
}
</script>

<style scoped>

</style>