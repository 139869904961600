<template>
  <header class="position-absolute bg-light bg-opacity-50 w-100">
    <div class="row p-3">
      <div class="col-6">
        <div class="row">
          <div class="col-md-6">
            <!--        Здоровье-->
            <label class="w-100" v-if="userSettings.health!==null">
              Здоровье
              <div class="progress">
                <div class="progress-bar bg-success"
                     v-bind:class="{'bg-warning': userSettings.health<60, 'bg-danger': userSettings.health<30}"
                     role="progressbar"
                     :style="'width: '+userSettings.health+'%;'"
                     :aria-valuenow="userSettings.health"
                     aria-valuemin="0"
                     aria-valuemax="100">{{ userSettings.health }}%
                </div>
              </div>
            </label>
          </div>
          <div class="col-md-6">
            <!--        Кристаллы-->
            <label class="w-100" v-if="userSettings.inventary && userSettings.inventary.cristalls">
              Кристаллы
              <div class="progress">
                <div class="progress-bar bg-danger" role="progressbar"
                     :style="'width: '+userSettings.inventary.cristalls+'%;'"
                     :aria-valuenow="userSettings.inventary.cristalls"
                     aria-valuemin="0"
                     aria-valuemax="100">{{ userSettings.inventary.cristalls }}%
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="col-6">
        <!--        таймер-->
        <label v-if="gameSettings.timer!==null">
          Время
          <timer :seconds="gameSettings.timer*60"
                 @finish="stopGame"
                 @tick="changeTimeDuration"
          ></timer>
        </label>

        <div class="float-end">
          <button type="button" class="btn btn-danger" @click="$emit('restart')">
            <font-awesome-icon icon="arrows-rotate"/>
          </button>
        </div>

      </div>
    </div>
  </header>
</template>

<script>

import Timer from "@/components/timer";
import changeEmit from "@/mixins/changeEmit";


export default {
  name: "templateHeader",
  components: {Timer},
  props: {
    userSettings: Object,
    gameSettings: Object,
  },
  emits: [
    'change',
    'restart'
  ],
  mixins: [changeEmit],
  methods: {
    stopGame() {
      this.sendEmit('gameSettings.gameState', 'fail');
    },
    changeTimeDuration(event) {
      this.sendEmit('gameSettings.diffTimer', event);
    }
  }
}
</script>

<style scoped>
header {
  z-index: 99999;
}
</style>