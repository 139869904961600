<script>
export default {
  name: "gameSettings",
  data() {
    return {
      gameSettings: {
        load: true,
        gameState: null, //Состояние игры (null, load, game, fail, win)
        timer: 10, //В минутах
        diffTimer: null, //остаток времени
        showHeader: true,
        showFooter: false
      },
    }
  },
  watch: {
    'gameSettings.diffTimer'() {
      this.saveCasheSettings();
    },
    'gameSettings.gameState'() {
      if (this.gameSettings.gameState === 'fail' || this.gameSettings.gameState === 'win') {
        this.gameSettings.showHeader = false;
        this.gameSettings.showFooter = false;
        document.querySelector('a-scene').systems["mindar-image-system"].stop()
      } else if (this.gameSettings.gameState === 'game') {
        document.querySelector('a-scene').systems["mindar-image-system"].start();
        this.gameSettings.showHeader = true;
        this.gameSettings.showFooter = false;
      }
    },
  },
  methods: {
    //Инициализация настроек игры по заданному объекту
    initGameSetting(settings) {
      if (settings.health) {
        this.userSettings.health = settings.health
      }

      if (settings.game_timer) {
        this.gameSettings.timer = settings.game_timer;
      }

      if (settings.show_header) {
        this.gameSettings.showHeader = settings.show_header;
      }

      if (settings.show_footer) {
        this.gameSettings.showFooter = settings.show_footer;
      }
    },
  }
}
</script>

<style scoped>

</style>