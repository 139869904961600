<template>
  <a-entity @click="event" id="informationMarker">
    <template v-if="marker.information_marker && marker.information_marker.src_type==='image'">
      <a-plane :src="marker.information_marker.src_url"
               position="0 0 0" rotation="0 0 0"
               scale="0.5 0.5"
      ></a-plane>
    </template>
    <template v-else-if="marker.information_marker && marker.information_marker.src_type==='video'">
      <a-video src="#video"></a-video>
    </template>

    <a-text :value="marker.information_marker && marker.information_marker.text"
            font="/assets/fonts/Nunito-VariableFont_wght-msdf.json"
            width="2"
            color="red"
            align="center"
            position="0 -0.4 0"
            negate="false"></a-text>


  </a-entity>

</template>

<script>
/*
Пример запуска аудио
https://codepen.io/mnebeling/pen/dyMPLRN

https://msdf-bmfont.donmccurdy.com/ генератор шрифтов (надо набрать символы которые должны быть выведены)

АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя
ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz
1234567890
.,"!()[]{}?/\<>@#$%^&*_

 */
import marker from "@/mixins/marker";

export default {
  name: "informationMarker",
  mixins: [marker],
  data() {
    return {
      audio: null
    }
  },
  watch: {
    'marker.targetLost'() {
      if (this.$props.marker.targetLost === true && this.$props.marker.information_marker.src_type) {
        switch (this.$props.marker.information_marker.src_type) {
          case 'audio':
            this.pauseAudio();
            break;
          case 'video':
            this.pauseVideo();
            break;
        }
      }
    }
  },
  methods: {
    event() {
      if (this.$props.marker.information_marker.src_type) {
        switch (this.$props.marker.information_marker.src_type) {
          case 'audio':
            this.playAudio();
            break;
          case 'video':
            this.playVideo();
            break;
        }
      }
    },
    playVideo() {
      let video = document.getElementById('video');
      if (video) {
        video.setAttribute('src', this.$props.marker.information_marker.src_url)
        video.play();
      } else {
        setTimeout(this.playVideo, 500);
      }
    },
    pauseVideo() {
      let video = document.getElementById('video');
      video.pause();
    },
    playAudio() {
      this.audio = new Audio(this.$props.marker.information_marker.src_url);
      this.audio.play();
    },
    pauseAudio() {
      this.audio.pause();
    }
  }

}
</script>

<style scoped>

</style>