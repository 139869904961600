<template>
  <div class="position-absolute bg-danger w-100 h-100 d-flex align-items-center">
    <div class="align-middle text-center w-100 h1 placeholder-glow">
      <ul class="list-unstyled">
        <li>
          <h3>Игра окончена</h3>
        </li>
        <li>
          <button type="button" class="btn btn-lg btn-success w-25" @click="$emit('restart')">
            <font-awesome-icon icon="arrows-rotate"/>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "templateGameOver",
  emits: [
    'restart'
  ]
}
</script>

<style scoped>

</style>