<template>
  <template-game-list-maslenica v-if="createScene===false" :game-list="gamesList" @change="changeSettings"/>

  <template v-else>
    <template-header-maslenica
        v-if="gameSettings.showHeader===true"
        :game-settings="gameSettings"
        :user-settings="userSettings"
        @change="changeSettings"
        @restart="restartGame"
    />

    <a-scene v-if="gameSettings.gameState==='play'"
             mindar-image="imageTargetSrc: /assets/maslenica2.mind; uiLoading: no; uiScanning: no; filterMinCF:0.005; filterBeta: 1000"
             color-space="sRGB" renderer="colorManagement: true, physicallyCorrectLights"
             vr-mode-ui="enabled: false"
             device-orientation-permission-ui="enabled: false"
             loading-screen="dotsColor: #fb6d05; backgroundColor: #feea70"
    >
      <a-camera position="0 0 0" look-controls="enabled: false" cursor="fuse: false; rayOrigin: mouse;">
        <a-cursor></a-cursor>
      </a-camera>

      <a-assets>
        <!--        <a-asset-item id="cristal" src="/assets/3dMarkers/crystal/scene.gltf"></a-asset-item>-->
        <!--        <a-asset-item id="bomb" src="/assets/3dMarkers/bomb/scene.gltf"></a-asset-item>-->
        <!--        <a-asset-item id="aid" src="/assets/3dMarkers/aid/scene.gltf"></a-asset-item>-->
        <!--        <a-asset-item id="timer" src="/assets/3dMarkers/timer/scene.gltf"></a-asset-item>-->
        <a-asset-item id="question" src="/assets/3dMarkers/question/scene.gltf"></a-asset-item>
        <video id="video"
               crossorigin="anonymous"
               preload="auto"
               autobuffer
               loop="true"
               playsinline
               webkit-playsinline
               timeout="10000"
               src="">
        </video>
      </a-assets>

      <a-entity v-for="(marker, markerIndex) in markers"
                :key="markerIndex+'_'+marker.sort"
                :mindar-image-target="'targetIndex:'+marker.sort"
                :event-type="marker.event_type.value"
                :data-target="'targetIndex:'+marker.sort"
                :data-show="marker.show"
                :data-marker="markerIndex"
      >
        <component :is="renderMarker(marker.template)"
                   :userSettings="userSettings"
                   :gameSettings="gameSettings"
                   :marker="marker"
                   :key="marker.id"
                   @change="changeSettings"
        ></component>
      </a-entity>


    </a-scene>
    <template-load-game v-if="gameSettings.gameState==='load'"/>
    <template-game-over-maslenica v-if="gameSettings.gameState==='fail'" @restart="restartGame"/>
    <template-game-success-maslenica
        v-if="gameSettings.gameState==='win'"
        @restart="restartGame"
        :userSettings="userSettings"
        :gameSettings="gameSettings"
    />
    <template-footer-maslenica v-if="gameSettings.showFooter===true"
                               :user-settings="userSettings"
                               @change="changeSettings"
    />
  </template>

</template>

<script>
import userSettings from "@/mixins/userSettings";
import gameSettings from "@/mixins/gameSettings";
import gql from "@/mixins/gql";
import gameEvents from "@/mixins/gameEvents";

import AidMarker from "@/components/markers/aidMarker";
import CristalMarker from "@/components/markers/cristalMarker";
import BombMarker from "@/components/markers/bombMarker";
import TimerMarker from "@/components/markers/timerMarker";
import QuestionMarker from "@/components/markers/questionMarker";
import InformationMarker from "@/components/markers/informationMarker";

import TemplateHeader from "@/components/templates/default/templateHeader";
import TemplateFooter from "@/components/templates/default/templateFooter";
import TemplateGameOver from "@/components/templates/default/templateGameOver";
import TemplateLoadGame from "@/components/templates/default/templateLoadGame";
import TemplateGameList from "@/components/templates/default/templateGameList";
import TemplateGameSuccess from "@/components/templates/default/templateGameSuccess";
import TemplateHeaderMaslenica from "@/components/templates/default/templateHeaderMaslenica.vue";
import TemplateFooterMaslenica from "@/components/templates/default/templateFooterMaslenica.vue";
import TemplateGameListMaslenica from "@/components/templates/default/templateGameListMaslenica.vue";
import TemplateGameSuccessMaslenica from "@/components/templates/default/templateGameSuccessMaslenica.vue";
import TemplateGameOverMaslenica from "@/components/templates/default/templateGameOverMaslenica.vue";

export default {
  name: 'App',
  data() {
    return {
      createScene: false,
      game_id: null,
      gamesList: null,
      gameInfo: null,
      markers: null
    }
  },
  props: [
    'graphqlurl'
  ],
  mixins: [
    userSettings,
    gameSettings,
    gql,
    gameEvents
  ],
  components: {
    TemplateGameListMaslenica,
    TemplateFooterMaslenica,
    TemplateHeaderMaslenica,
    TemplateGameSuccessMaslenica,
    TemplateGameOverMaslenica,
    TemplateGameSuccess,
    TemplateGameList,
    TemplateLoadGame,
    TemplateGameOver,
    TemplateHeader,
    TemplateFooter,
    QuestionMarker,
    BombMarker,
    CristalMarker,
    AidMarker,
    TimerMarker,
    InformationMarker
  },
  created() {
    //Получим список игр
    this.gqlRequest(
        this.getGameListQueryString(),
        (response) => {
          if (response.data) {
            this.gamesList = response.data.ar_games;
          }
        }
    );
  },
  watch: {
    game_id() {
      this.init();
    },
    createScene() {
      setTimeout(() => {
        document.querySelector('a-scene').addEventListener("loaded", () => {
          console.info('Aframe load');
        });

        document.querySelector('a-scene').addEventListener("arReady", () => {
          console.info('Aframe init');
          this.gameSettings.load = false;
        });

        document.querySelector('a-scene').addEventListener("arError", () => {
          console.error('Ошибка инициализации');
        });
      }, 2000);
    },
    markers() {
      setTimeout(() => {
        //Для Клика не нужна никакая инициализация
        document.querySelectorAll('a-entity[event-type="hover"]').forEach((item) => {
          item.addEventListener("targetFound", event => {
                this.markers[item.dataset.marker].visible = true;
                event.target.firstChild.click();
              },
              true
          );
        });

        document.querySelectorAll('a-entity').forEach((item) => {

          item.addEventListener("targetFound", () => {
                this.markers[item.dataset.marker].targetLost = false;
                this.markers[item.dataset.marker].visible = true;
              },
              true
          );

          item.addEventListener("targetLost", () => {
            this.markers[item.dataset.marker].targetLost = true;
          }, true);

        });

      }, 500);
    }
  },
  methods: {
    renderMarker(template) {
      /* eslint-disable */
      switch (template.value) {
        case 'aid':
          return 'aid-marker';
        case 'bomb':
          return 'bomb-marker';
        case 'cristall':
          return 'cristal-marker';
        case 'time':
          return 'timer-marker';
        case 'question':
          return 'question-marker';
        case 'info':
          return 'information-marker';
      }
      /* eslint-enable */
    },
    //Инициализация игры
    init() {
      let request = this.getMarkersQueryString(this.game_id);

      let cachedGameInfo = localStorage.getItem('gameInfo');
      let cachedMarkers = localStorage.getItem('markers');
      if (cachedGameInfo && cachedMarkers) {
        this.gameInfo = JSON.parse(cachedGameInfo);

        this.markers = JSON.parse(cachedMarkers);
        this.markers.map((item) => {
          item.show = true;
          item.visible = false;
        });
        this.getCachedSettings();
        //Запускаем игру
        this.gameSettings.gameState = 'play';
        this.createScene = true;
        this.gameSettings.showHeader = true;
      } else {
        this.gqlRequest(
            request,
            (response) => {
              if (response.data) {
                if (response.data.ar_games) {
                  localStorage.setItem('gameInfo', JSON.stringify(response.data.ar_games[0]));
                  this.gameInfo = response.data.ar_games[0];
                  this.initGameSetting(this.gameInfo.settings);
                }

                if (response.data.ar_markers) {
                  localStorage.setItem('markers', JSON.stringify(response.data.ar_markers));
                  this.markers = response.data.ar_markers;
                  this.markers.map((item) => {
                    item.show = true;
                    item.visible = false;
                  });
                }

                this.saveCasheSettings();

                //Запускаем игру
                this.gameSettings.gameState = 'play';
                this.createScene = true;
                this.gameSettings.showHeader = true;
              }
            }
        );
      }
    },

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
}
</style>
